<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <h1 class="m-0 text-dark">All Merchant</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-8">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                        <router-link to="/iregular-merchant" class="btn btn-success float-right mr-2"> Irregular Merchant</router-link>
                        <router-link to="/merchant-parcel-summary" class="btn btn-success float-right mr-2"> Parcel Summary</router-link>
                        <router-link to="/merchant-account-change-request" class="btn btn-warning float-right mr-2"> Account Change Request ({{ changeRequest }})</router-link>
                        <button class="btn btn-secondary float-right mr-2">
                            <a href="https://merchant.bahokcourier.com" target="_blank" style="color:#fff">Merchant Panel</a>
                        </button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="business">Business Name</label>
                                                <input type="text" id="business" v-model="search.business" placeholder="Enter Business Name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">
                                                <label for="id">ID</label>
                                                <input type="text" id="id" v-model="search.id" placeholder="Enter ID" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="mobile">Mobile No</label>
                                                <input type="text" id="mobile" v-model="search.mobile" placeholder="Enter Mobile No" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="status">Source Hub</label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="status">Status</label>
                                                <select type="text" id="status" v-model="search.status" class="form-control">
                                                    <option :value="0">Select</option>
                                                    <option :value="1">Pending</option>
                                                    <option :value="2">Approved</option>
                                                    <option :value="3">Incomplete</option>
                                                    <option :value="4">Reject</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                                <a v-if="authUser.role_id <= 2" class="btn btn-warning btn-sm mr-2" target="_blank" :href="$baseUrl + 'excel-download/merchant?business='+search.business+'&mobile='+search.mobile+'&status='+search.status+'&id='+search.id">
                                                    <i class="fa fa-download"></i> Excel
                                                </a>
                                                <a v-if="authUser.role_id <= 2" class="btn btn-primary btn-sm" target="_blank" :href="$baseUrl + 'excel-download/merchant-bank-acc'">
                                                    <i class="fa fa-download"></i> Bank Acc
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total Merchant</th>
                                                    <th>Active Merchant</th>
                                                    <th>Pending Merchant</th>
                                                    <th>Reject Merchant</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold">
                                                    <td>{{ summary.total_merchant }}</td>
                                                    <td>{{ summary.active_merchant }}</td>
                                                    <td>{{ summary.pending_merchant }}</td>
                                                    <td>{{ summary.reject_merchant }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body mr-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Merchant ID</th>
                                                    <th>Merchant Details </th>
                                                    <th>Business Name</th>
                                                    <th>Service Charge</th>
                                                    <th>COD(%)</th>
                                                    <th>Return Charge</th>
                                                    <th>Address</th>
                                                    <th>Source Hub</th>
                                                    <th>Reg. Date</th>
                                                    <th>KAM</th>
                                                    <th>Status</th>
                                                    <th>Action By</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Merchant ID</th>
                                                    <th>Merchant Details </th>
                                                    <th>Business Name</th>
                                                    <th>Service Charge</th>
                                                    <th>COD(%)</th>
                                                    <th>Return Charge</th>
                                                    <th>Address</th>
                                                    <th>Source Hub</th>
                                                    <th>Reg. Date</th>
                                                    <th>KAM</th>
                                                    <th>Status</th>
                                                    <th>Action By</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(merchant,key) in listData" :key="merchant.id">
                                                    <td>BHKM{{ merchant.id }}</td>
                                                    <td @click="editMerchant(merchant)">{{ merchant.name }} <br/>{{ merchant.mobile }} <br/>{{ merchant.email }}</td>
                                                    <td @click="otherDettials(merchant.account)">{{ merchant.business }}</td>
                                                    <td>{{ 'SC: '+ merchant.same_city_charge + ', ISD: '+ merchant.isd_charge + ', OSD: ' + merchant.osd_charge + ', SA: ' + merchant.sa_charge }}</td>
                                                    <td>{{ 'SC: '+ merchant.same_city_cod + ', ISD: '+ merchant.isd_cod + ', OSD: '+ merchant.osd_cod + ', SA: ' + merchant.sa_cod  }}</td>
                                                    <td>{{ 'SC: '+ merchant.same_city_return_charge + ', ISD: '+ merchant.isd_return_charge + ', OSD: '+ merchant.osd_return_charge + ', SA: ' + merchant.sa_return_charge  }}</td>
                                                    <td>{{ merchant.address }}</td>
                                                    <td>{{ merchant.hub ? merchant.hub.name : '' }}</td>
                                                    <td>{{ merchant.created_at | dateformat }}</td>
                                                    <td>{{ merchant.kam_user ? merchant.kam_user.name : '' }}</td>
                                                    <td>{{ getStatus(merchant.status) }}</td>
                                                    <td>
                                                        {{ merchant.createdby ? "CB: "+ merchant.createdby.name : '' }}
                                                        <br/>
                                                        {{ merchant.updatedby ? "UB: "+ merchant.updatedby.name : '' }}
                                                    </td>
                                                    <td style="text-align:center;">                                    
                                                        <button v-if="merchant.status == 1 || merchant.status == 4" class="btn btn-success btn-sm mr-1" @click="statusUpdate(2, merchant.id, key)" title="Approve"><i class="fas fa-check"></i></button>
                                                        <button v-if="merchant.status == 2" class="btn btn-danger btn-sm mr-1" @click="statusUpdate(4, merchant.id)" title="Reject"><i class="fas fa-user-slash"></i></button>                                                       
                                                        <button class="btn btn-warning btn-sm mr-1" @click="editMerchant(merchant)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                        <button v-if="merchant.status == 1" class="btn btn-danger btn-sm"  @click="deleteMerchant(key, merchant.id)" title="Delete"><i class="fas fa-trash"></i></button>
                                                        <!-- <button v-if="merchant.status != 1" class="btn btn-info btn-sm"  @click="printQrCode(merchant.id)" title="QR Code"><i class="fa fa-qrcode"></i></button> -->
                                                        <button v-if="merchant.status != 1" class="btn btn-secondary btn-sm"  @click="showSLAModal(merchant.id, merchant.sla)" title="SLA"><i class='fas fa-file-contract'></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <b-overlay :show="loader">
                            <div class="modal-header bg-info text-white">
                                <h5 class="modal-title text-center w-100">Add New Merchant</h5>
                                <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(createMerchant)">
                                            <div class="row">
                                                <div class="col-12 mb-1">
                                                    <h5>General Information: </h5>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="name">Name </label>
                                                            <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Business Name" vid="business" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="business">Business Name <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="business" v-model="form.business" class="form-control" placeholder="Business Name">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Facebook Page Link" vid="fb_page_link" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="fb_page_link">Facebook Page Link </label>
                                                            <input type="text" id="fb_page_link" v-model="form.fb_page_link" class="form-control" placeholder="Facebook Page Link">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="NID" vid="nid" v-slot="{errors}" rules="required|numeric">
                                                        <div class="form-group">
                                                            <label for="nid">NID </label>
                                                            <input type="text" id="nid" v-model="form.nid" class="form-control" placeholder="NID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>        
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                        <div class="form-group">
                                                            <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>      
                                                    </ValidationProvider>                  
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Email" vid="email" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="email">Email </label>
                                                            <input type="text" id="email" v-model="form.email" class="form-control" placeholder="Email">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>      
                                                    </ValidationProvider>                
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Account Type" vid="account_type" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="account_type">Account Type <span class="text-danger" title="Required">*</span></label>                                                        
                                                            <v-select name="hub_id"
                                                                v-model="form.account_type"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= merchantAccountList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />  
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>        
                                                    </ValidationProvider>             
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="form.account_type == 1">
                                                    <ValidationProvider name="Bank" vid="bank_id" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="account_type">Bank</label>
                                                            <v-select name="bank_id"
                                                                v-model="form.bank_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.bankList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />                        
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>         
                                                    </ValidationProvider>             
                                                </div>                    
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12"  v-if="form.account_type == 1">
                                                    <ValidationProvider name="Branch" vid="branch" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="branch">Branch </label>
                                                            <input type="text" id="branch" v-model="form.branch" class="form-control" placeholder="Branch">                               
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>                       
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="form.account_type != 5">
                                                    <ValidationProvider name="Account Name" vid="account_name" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="account_name">Account Name </label>
                                                            <input type="text" id="account_name" v-model="form.account_name" autocomplete="off" class="form-control" placeholder="Account Name">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>           
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="form.account_type != 5">
                                                    <ValidationProvider name="Account Number" vid="account_number" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="account_number">Account Number </label>
                                                            <input type="text" id="account_number" v-model="form.account_number" class="form-control" placeholder="Account Number">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div>         
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="form.account_type == 1">
                                                    <ValidationProvider name="Routing Number" vid="routing_no" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="routing_no">Routing Number </label>
                                                            <input type="text" id="routing_no" v-model="form.routing_no" class="form-control" placeholder="Account Number">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div>         
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="address" v-model="form.address" class="form-control" placeholder="Address">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label for="image">Image</label>
                                                        <input type="file" id="image" @change="onImageChange" class="form-control">
                                                    </div>
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="hub_id"
                                                                v-model="form.hub_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.hubList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />  
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>  
                                                    </ValidationProvider>                    
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="KAM" vid="kam" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="kam">KAM <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="kam"
                                                                v-model="form.kam"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= userList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />  
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>  
                                                    </ValidationProvider>                    
                                                </div>     
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="district_id"
                                                                v-model="form.district_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.districtList
                                                                :placeholder="$t('globalTrans.select')"
                                                            />                        
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>         
                                                    </ValidationProvider>             
                                                </div>                     
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Password" vid="password" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="password">Password <span class="text-danger" title="Required">*</span></label>
                                                            <input type="password" id="password" v-model="form.password" autocomplete="off" class="form-control" placeholder="Password">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Confirm Password" vid="confirm_password" v-slot="{errors}" rules="required|confirmed:password">
                                                        <div class="form-group">
                                                            <label for="confirm_password">Confirm Password <span class="text-danger" title="Required">*</span></label>
                                                            <input type="password" id="confirm_password" v-model="form.confirm_password" autocomplete="off" class="form-control" placeholder="Confirm Password">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>         
                                                    </ValidationProvider>            
                                                </div> 

                                                <div class="col-12 mb-1">
                                                    <h5>Charge Details: </h5>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Same City Delivery Charge" vid="same_city_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="same_city_charge">Same City Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="same_city_charge" v-model="form.same_city_charge" class="form-control" placeholder="Same City Delivery Charge">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Same City COD" vid="same_city_cod" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="same_city_cod">Same City COD (%)<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="same_city_cod" v-model="form.same_city_cod" class="form-control" placeholder="Same City COD">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Same City Return Charge" vid="same_city_return_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="same_city_return_charge">Same City Return Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="same_city_return_charge" v-model="form.same_city_return_charge" class="form-control" placeholder="Same City Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 

                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="ISD Delivery Charge" vid="isd_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="isd_charge">ISD Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="isd_charge" v-model="form.isd_charge" class="form-control" placeholder="ISD Delivery Charge">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Inside Dhaka COD" vid="isd_cod" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="isd_cod">ISD COD (%)<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="isd_cod" v-model="form.isd_cod" class="form-control" placeholder="Inside Dhaka COD">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Inside Dhaka Return Charge" vid="isd_return_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="isd_return_charge">ISD Return Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="isd_return_charge" v-model="form.isd_return_charge" class="form-control" placeholder="Inside Dhaka Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div>  
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="OSD Delivery Charge" vid="osd_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="osd_charge">OSD Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="osd_charge" v-model="form.osd_charge" class="form-control" placeholder="OSD Delivery Charge">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="OSD COD" vid="osd_cod" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="osd_cod">OSD COD (%)<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="osd_cod" v-model="form.osd_cod" class="form-control" placeholder="Outside Dhaka COD">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Outside Dhaka Return Charge" vid="osd_return_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="osd_return_charge">OSD Return Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="osd_return_charge" v-model="form.osd_return_charge" class="form-control" placeholder="Outside Dhaka Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="SA Delivery Charge" vid="sa_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="sa_charge">SA Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="sa_charge" v-model="form.sa_charge" class="form-control" placeholder="SA Delivery Charge">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Sub Area COD" vid="sa_cod" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="sa_cod">SA COD (%)<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="sa_cod" v-model="form.sa_cod" class="form-control" placeholder="Sub Area COD">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Sub Area Return Charge" vid="sa_return_charge" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="sa_return_charge">SA Return Charge<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="sa_return_charge" v-model="form.sa_return_charge" class="form-control" placeholder="Sub Area Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>    
                                                    </ValidationProvider>                  
                                                </div> 
                                            </div>
                                        </form>
                                    </ValidationObserver>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info" @click.prevent="createMerchant">Save</button>
                                <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                            </div>
                        </b-overlay>
                    </div>
                </b-overlay>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Merchant</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateMerchant)">
                                    <div class="row">
                                        <div class="col-12 mb-1">
                                            <h5>General Information: </h5>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="fullname">Name </label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                    <input type="hidden" id="name" v-model="form_edit.id">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider> 
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Business Name" vid="business" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="business">Business Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="business" v-model="form_edit.business" class="form-control" placeholder="Business Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Facebook Page Link" vid="fb_page_link" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="fb_page_link">Facebook Page Link </label>
                                                    <input type="text" id="fb_page_link" v-model="form_edit.fb_page_link" class="form-control" placeholder="Facebook Page Link">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                                </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="NID" vid="nid" v-slot="{errors}" rules="required|numeric">
                                                <div class="form-group">
                                                    <label for="nid">NID </label>
                                                    <input type="text" id="nid" v-model="form_edit.nid" class="form-control" placeholder="NID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>          
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Email" vid="email" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="email">Email </label>
                                                    <input type="text" id="email" v-model="form_edit.email" class="form-control" placeholder="Email">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>     
                                            </ValidationProvider>                 
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Account Type" vid="account_type" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="account_type">Account Type <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form_edit.account_type"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= merchantAccountList
                                                        :placeholder="$t('globalTrans.select')"
                                                    /> 
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div> 
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="form_edit.account_type == 1">
                                            <ValidationProvider name="Bank" vid="bank_id" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="account_type">Bank</label>
                                                    <v-select name="bank_id"
                                                        v-model="form_edit.bank_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.bankList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />                         
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>          
                                            </ValidationProvider>            
                                        </div>                    
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Branch" vid="branch" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="branch">Branch </label>
                                                    <input type="text" id="branch" v-model="form_edit.branch" class="form-control" placeholder="Branch">                               
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                       
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Account Name" vid="account_name" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="account_name">Account Name </label>
                                                    <input type="text" id="account_name" v-model="form_edit.account_name" class="form-control" placeholder="Account Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>         
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Account Number" vid="account_number" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="account_number">Account Number </label>
                                                    <input type="text" id="account_number" v-model="form_edit.account_number" class="form-control" placeholder="Account Number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>   
                                            </ValidationProvider>                   
                                        </div>       
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="form_edit.account_type == 1">
                                            <ValidationProvider name="Routing Number" vid="routing_no" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="routing_no">Routing Number </label>
                                                    <input type="text" id="routing_no" v-model="form_edit.routing_no" class="form-control" placeholder="Routing Number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="image">Image</label>
                                                <input type="file" id="image" @change="onImageChange" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="bank_id"
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    /> 
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                    
                                        </div>  
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="KAM" vid="kam" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="kam">KAM <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="kam"
                                                        v-model="form_edit.kam"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= userList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />  
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                    
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="district_id"
                                                        v-model="form_edit.district_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.districtList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />                        
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>         
                                            </ValidationProvider>             
                                        </div>       
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Password" vid="password" v-slot="{errors}">
                                                <div class="form-group">
                                                    <label for="password">Password </label>
                                                    <input type="password" v-model="form_edit.new_password" class="form-control" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-12 mb-1">
                                            <h5>Charge Details: </h5>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Same City Delivery Charge" vid="same_city_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="same_city_charge">Same City Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="same_city_charge" v-model="form_edit.same_city_charge" class="form-control" placeholder="Same City Delivery Charge">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Same City COD" vid="same_city_cod" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="same_city_cod">Same City COD (%)<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="same_city_cod" v-model="form_edit.same_city_cod" class="form-control" placeholder="Same City COD">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Same City Return Charge" vid="same_city_return_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="same_city_return_charge">Same City Return Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="same_city_return_charge" v-model="form_edit.same_city_return_charge" class="form-control" placeholder="Same City Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="ISD Delivery Charge" vid="isd_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="isd_charge">ISD Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="isd_charge" v-model="form_edit.isd_charge" class="form-control" placeholder="ISD Delivery Charge">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Inside Dhaka COD" vid="isd_cod" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="isd_cod">ISD COD (%)<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="isd_cod" v-model="form_edit.isd_cod" class="form-control" placeholder="Inside Dhaka COD">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Inside Dhaka Return Charge" vid="isd_return_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="isd_return_charge">ISD Return Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="isd_return_charge" v-model="form_edit.isd_return_charge" class="form-control" placeholder="Inside Dhaka Return Charge" >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div>


                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="OSD Delivery Charge" vid="osd_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="osd_charge">OSD Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="osd_charge" v-model="form_edit.osd_charge" class="form-control" placeholder="OSD Delivery Charge">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Outside Dhaka COD" vid="osd_cod" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="osd_cod">OSD COD (%)<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="osd_cod" v-model="form_edit.osd_cod" class="form-control" placeholder="Outside Dhaka COD">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Outside Dhaka Return Charge" vid="osd_return_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="osd_return_charge">OSD Return Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="osd_return_charge" v-model="form_edit.osd_return_charge" class="form-control" placeholder="Outside Dhaka Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 


                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="SA Delivery Charge" vid="sa_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="sa_charge">SA Delivery Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="sa_charge" v-model="form_edit.sa_charge" class="form-control" placeholder="SA Delivery Charge">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div> 
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Sub Area COD" vid="sa_cod" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="sa_cod">SA COD (%)<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="sa_cod" v-model="form_edit.sa_cod" class="form-control" placeholder="Sub Area COD">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div>  
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Sub Area Return Charge" vid="sa_return_charge" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="sa_return_charge">SA Return Charge<span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="sa_return_charge" v-model="form_edit.sa_return_charge" class="form-control" placeholder="Sub Area Return Charge" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                  
                                        </div>     
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateMerchant">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="accountDetailsModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Account Details</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <slot v-if="accountDetails">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bank_id">Bank </label>
                                            <v-select name="bank_id"
                                                v-model="accountDetails.bank_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= commonObj.bankList
                                                :disabled="true"
                                                :placeholder="$t('globalTrans.select')"
                                            /> 
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bank_acc_name">Bank Account Name </label>
                                            <input type="text" id="bank_acc_name" v-model="accountDetails.bank_acc_name" :disabled="true" class="form-control" placeholder="Bank Account Name">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bank_acc_no">Bank Account No </label>
                                            <input type="text" id="bank_acc_no" v-model="accountDetails.bank_acc_no" :disabled="true" class="form-control" placeholder="Bank Account No">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bank_branch">Bank Branch Name </label>
                                            <input type="text" id="bank_branch" v-model="accountDetails.bank_branch" :disabled="true" class="form-control" placeholder="Bank Branch Name">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bank_routing_no">Bank Routing No </label>
                                            <input type="text" id="bank_routing_no" v-model="accountDetails.bank_routing_no" :disabled="true" class="form-control" placeholder="Bank Routing No">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bkash_no">Bkash Account No </label>
                                            <input type="text" id="bkash_no" v-model="accountDetails.bkash_no" :disabled="true" class="form-control" placeholder="Bkash Account No">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="bkash_acc_type">Bkash Account Type </label>
                                            <v-select name="bkash_acc_type"
                                                v-model="accountDetails.bkash_acc_type"
                                                label="text"
                                                :reduce="item => item.id"
                                                :disabled="true"
                                                :options= mobileWalletTypeList
                                                :placeholder="$t('globalTrans.select')"
                                            /> 
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="nagad_no">Nagad Account No </label>
                                            <input type="text" id="nagad_no" v-model="accountDetails.nagad_no" :disabled="true" class="form-control" placeholder="Nagad Account No">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="nagad_acc_type">Nagad Account Type </label>
                                            <v-select name="nagad_acc_type"
                                                v-model="accountDetails.nagad_acc_type"
                                                label="text"
                                                :reduce="item => item.id"
                                                :disabled="true"
                                                :options= mobileWalletTypeList
                                                :placeholder="$t('globalTrans.select')"
                                            /> 
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rocket_no">Rocket Account No </label>
                                            <input type="text" id="rocket_no" v-model="accountDetails.rocket_no" :disabled="true" class="form-control" placeholder="Rocket Account No">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rocket_acc_type">Rocket Account Type </label>
                                            <v-select name="rocket_acc_type"
                                                v-model="accountDetails.rocket_acc_type"
                                                label="text"
                                                :reduce="item => item.id"
                                                :disabled="true"
                                                :options= mobileWalletTypeList
                                                :placeholder="$t('globalTrans.select')"
                                            /> 
                                        </div> 
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rocket_no">Company Email </label>
                                            <input type="text" id="rocket_no" v-model="accountDetails.company_email" :disabled="true" class="form-control" placeholder="Company Email Address">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rocket_no">Contact No 1 </label>
                                            <input type="text" id="rocket_no" v-model="accountDetails.contact_1" :disabled="true" class="form-control" placeholder="Contact No 1">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rocket_no">Contact No 2 </label>
                                            <input type="text" id="rocket_no" v-model="accountDetails.contact_2" :disabled="true" class="form-control" placeholder="Contact No 2">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rocket_no">Contact No 3 </label>
                                            <input type="text" id="rocket_no" v-model="accountDetails.contact_3" :disabled="true" class="form-control" placeholder="Contact No 3">
                                        </div>
                                    </div>
                                </div>
                            </slot>
                            <slot v-else>
                                <h4 class="text-center">Account details not found </h4>
                            </slot>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyMerchant">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="slaModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <b-overlay :show="loader">
                            <div class="modal-header bg-info text-white">
                                <h5 class="modal-title text-center w-100">Update SLA</h5>
                                <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                    <form @submit.prevent="handleSubmit(updateSLA)">
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Delivery SLA (ISD)" vid="delivery_sla_isd" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="delivery_sla_isd">Delivery SLA (ISD) Days </label>
                                                        <input type="text" id="delivery_sla_isd" v-model="merchant_sla.delivery_sla_isd" class="form-control" placeholder="Delivery SLA (ISD)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Delivery SLA (OSD)" vid="delivery_sla_osd" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="delivery_sla_osd">Delivery SLA (OSD) Days </label>
                                                        <input type="text" id="delivery_sla_osd" v-model="merchant_sla.delivery_sla_osd" class="form-control" placeholder="Delivery SLA (OSD)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Delivery SLA (SA)" vid="delivery_sla_sa" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="delivery_sla_sa">Delivery SLA (SA) Days </label>
                                                        <input type="text" id="delivery_sla_sa" v-model="merchant_sla.delivery_sla_sa" class="form-control" placeholder="Delivery SLA (SA)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Return SLA (ISD)" vid="return_sla_isd" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="return_sla_isd">Return SLA (ISD) Days </label>
                                                        <input type="text" id="return_sla_isd" v-model="merchant_sla.return_sla_isd" class="form-control" placeholder="Return SLA (ISD)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Return SLA (OSD)" vid="return_sla_osd" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="return_sla_osd">Return SLA (OSD) Days </label>
                                                        <input type="text" id="return_sla_osd" v-model="merchant_sla.return_sla_osd" class="form-control" placeholder="Return SLA (OSD)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Return SLA (SA)" vid="return_sla_sa" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="return_sla_sa">Return SLA (SA) Days </label>
                                                        <input type="text" id="return_sla_sa" v-model="merchant_sla.return_sla_sa" class="form-control" placeholder="Return SLA (SA)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Payment SLA (ISD)" vid="payment_sla_isd" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="payment_sla_isd">Payment SLA (ISD) Days </label>
                                                        <input type="text" id="payment_sla_isd" v-model="merchant_sla.payment_sla_isd" class="form-control" placeholder="Payment SLA (ISD)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Payment SLA (OSD)" vid="payment_sla_osd" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="payment_sla_osd">Payment SLA (OSD) Days </label>
                                                        <input type="text" id="payment_sla_osd" v-model="merchant_sla.payment_sla_osd" class="form-control" placeholder="Payment SLA (OSD)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <ValidationProvider name="Return SLA (SA)" vid="payment_sla_sa" v-slot="{errors}" rules="required|numeric">
                                                    <div class="form-group">
                                                        <label for="payment_sla_sa">Payment SLA (SA) Days </label>
                                                        <input type="text" id="payment_sla_sa" v-model="merchant_sla.payment_sla_sa" class="form-control" placeholder="Payment SLA (SA)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>    
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info" @click.prevent="updateSLA">Update</button>
                                <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                            </div>
                        </b-overlay>
                    </div>
                </b-overlay>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    import QrCode from './QrCode.js'
    export default {
        name:'Merchant',
        data () {
            return { 
                loader: false,   
                hubs:{},
                merchants:{
                    data:[]
                },
                charges:{},
                banks:{},
                changeRequest: 0,
                search: {
                    name: '',
                    business: '',
                    mobile: '',
                    id: 0,
                    hub_id: '',
                    status: 0
                },
                form:{
                    name : '',
                    mobile : '',
                    email : '',
                    business : '',
                    nid : '',
                    account_type : 5,
                    bank_id : '',
                    branch : '',
                    account_name : '',
                    account_number : '',
                    routing_no : '',
                    status : 1,
                    hub_id : '',
                    address : '',
                    image : '',
                    password : '',
                    confirm_password : '',
                    cod: 0,
                    isd_charge: 0,
                    osd_charge: 0,
                    sa_charge: 0,
                    isd_cod: 0,
                    osd_cod: 0,
                    sa_cod: 0,
                    isd_return_charge: 0,
                    osd_return_charge: 0,
                    sa_return_charge: 0,
                    kam: ''
                },
                summary: null,
                accountDetails: null,
                upload_image:[],
                form_edit:'',
                errors:[],
                createModal:false,
                editModal:false,
                accountDetailsModal:false,
                deleteModal:false,
                slaModal:false,
                userLoading:false,
                userList: [],
                merchant_id:'',
                mobileWalletTypeList: [
                    { id: 1, text: 'Personal'},
                    { id: 2, text: 'Agent'},
                ],
                merchant_sla: null,
                defaultSla: {
                    delivery_sla_isd: 2,
                    delivery_sla_osd: 4,
                    delivery_sla_sa: 3,
                    return_sla_isd: 3,
                    return_sla_osd: 7,
                    return_sla_sa: 5,
                    payment_sla_isd: 5,
                    payment_sla_osd: 7,
                    payment_sla_sa: 6,
                },
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            this.loadUserList()
            if (this.$route.query) {
                Object.assign(this.search, this.$route.query)
            } 
            this.setDefaultCharge()
            this.loadSummary()
            this.loadData()
            this.loadChangeRequestSummary()
        },
        watch: {
            'search.business': function (newVal, oldVal) {
                if(newVal != oldVal) {                   
                    this.loadData()
                }
            },
            '$route.query.id': function (newVal, oldVal) {
                if(newVal != oldVal) {
                    Object.assign(this.search, { id: this.$route.query.id })
                    this.loadSummary()
                    this.loadData()
                }
            },
            stateReload: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            merchantAccountList () {
                return this.$store.state.merchantAccountList
            }
        },
        methods:{
            printQrCode(merchantId) {
                QrCode.expostQrCode(merchantId)
            },
            onImageChange(e){
                this.upload_image = e.target.files[0];
            },
            searchData () {
                this.loadSummary()
                this.loadData()
            },
            async loadSummary () {
                this.loader = true
                const params = this.search
                const response = await config.getData('/merchant/summary', params)
                this.loader = false
                this.summary = response.data          
            },
            async loadChangeRequestSummary () {
                const response = await config.getData('/merchant/account-change-request-summary')
                this.changeRequest = response.data          
            },
            async loadData(){       
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })       
                const response = await config.getData('/merchant', params)
                this.loader = false
                if (response.status == 200) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showCreateModal(){
                this.createModal = true;
                document.body.classList.add("modal-open");
            },
            async createMerchant(){
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData();
                Object.keys(this.form).map(key => {
                    if (key == 'image') {
                        formData.append(key, this.upload_image)
                    } else {
                        formData.append(key, this.form[key])
                    }
                })

                const response = await config.postData('/merchant/store', formData)

                if (response.status == 201) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.createModal = false;
                    this.form = ''           
                    this.$toast.success({
                        title: 'Success',
                        message: 'Merchant created successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            cancelModal(){
                this.createModal = false;
                this.editModal = false;
                this.accountDetailsModal = false;
                this.deleteModal = false;
                this.slaModal = false;
                document.body.classList.remove("modal-open");
            },
            editMerchant(merchant){
                this.editModal = true;
                this.form_edit = Object.assign(merchant, { new_password: ''});
                document.body.classList.add("modal-open");
            },
            otherDettials(accountDetailsInfo){
                this.accountDetailsModal = true;  
                this.accountDetails = accountDetailsInfo;
                document.body.classList.add("modal-open");
            },
            async updateMerchant(){
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData();
                Object.keys(this.form_edit).map(key => {
                    if (key == 'image') {
                        formData.append(key, this.upload_image)
                    } else {
                        formData.append(key, this.form_edit[key])
                    }
                })

                const response = await config.postData('/merchant/update', formData)
                this.loader = false
                if (response.status == 201) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.editModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'Merchant updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            deleteMerchant(key, merchant_id){
                this.merchant_id = merchant_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroyMerchant(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.getData('/merchant/destroy/'+ this.merchant_id)
                this.loader = false   
                if (response.status == 200) {      
                    this.deleteModal = false
                    this.$toast.success({
                        title: 'Success',
                        message: 'Merchant deleted successfully',
                        color: '#218838'
                    }) 
                }  else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })  
                }
            },
            async statusUpdate(status, merchant_id){ 
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const params = {merchant_id : merchant_id,status: status}
                const response = await config.postData('/merchant/status/update', params)
                this.loader = false
                if (response.status == 201) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.editModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'Status updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            setDefaultCharge () {
                this.form.isd_charge = this.commonObj.coverageAreaList[0].amount
                this.form.osd_charge = this.commonObj.coverageAreaList[1].amount
                this.form.sa_charge = this.commonObj.coverageAreaList[2].amount
            },
            getStatus (status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'Approved'
                } else if (status == 3) {
                    return 'Incomplete'
                } else {
                    return 'Reject'
                }
            },
            async loadUserList () {
                this.userLoading = true
                const response = await config.getData('/user/all')
                this.userLoading = false    
                if (response.status === 200) {
                    this.userList = response.data
                } else {
                    this.userList = []
                }
            },
            showSLAModal(merchantId, merchantSla) {
                if (!merchantSla) {
                    this.merchant_sla = Object.assign(this.defaultSla, { merchant_id: merchantId })
                } else {
                    this.merchant_sla = Object.assign(merchantSla, { merchant_id: merchantId })
                }                
                this.slaModal = true;
                document.body.classList.add("modal-open");
            },
            async updateSLA(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.postData('/merchant/sla-update', this.merchant_sla)
                this.loader = false
                if (response.status == 201) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.editModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'Merchant SLA updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>